import React, { forwardRef } from "react"
import Icon from "@mdi/react"
import { mdiArrowRight } from "@mdi/js"
import { LinkAnim } from "./link"

const Anchor = forwardRef((props, ref) => (
  <LinkAnim {...props} ref={ref}>
    {props.children}
    <span className="icon">
      <Icon path={props.icon ? props.icon : mdiArrowRight} />
    </span>
  </LinkAnim>
))

const Button = forwardRef((props, ref) => (
  <button {...props} ref={ref}>
    {props.children}
    <span className="icon">
      <Icon path={props.icon ? props.icon : mdiArrowRight} />
    </span>
  </button>
))

export default forwardRef((props, ref) => {
  return props.type === "button" || props.type === "submit" ? (
    <Button {...props} ref={ref} />
  ) : (
    <Anchor {...props} ref={ref} />
  )
})
