import React from "react"
import Layout from "../components/layout"
import BookForm from "../components/bookform"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import BackgroundImage from "gatsby-background-image"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import "../styles/pages/book.scss"
import Obfuscate from 'react-obfuscate';

const BookPage = ({ data }) => {
  const { t } = useTranslation("book")

  return (
    <Layout>
      <SEO
        title={t("metadata.title")}
        description={t("metadata.desc")}
        image="book.jpg"
      />
      <div className="container-fluid">
        <div className="row book-row">
          <div className="col-12 col-xl-2 book-background">
            <BackgroundImage
              Tag="div"
              fluid={data.image1.childImageSharp.fluid}
              backgroundColor={`#000`}
              style={{
                backgroundPosition: "50% center",
              }}
            />
          </div>
          <div className="col-12 col-xl-3 contactInfo">
            <h1 className="text--sm sp-mt-2 color--black">
              {t("contact.title")}
            </h1>
            <p
              className="text--sm color--black">
              <span dangerouslySetInnerHTML={{__html: t("contact.content")}}></span>
              <br />
              <Obfuscate
                email="hello@thehighloft.com"
              />
            </p>
          </div>
          <div className="col-12 col-xl-7 sp-pb-9">
            <div className="formIntro sp-mb-7">
              <h2 className="text--sm sp-mt-2 color--black">
                {t("form.intro.title")}
              </h2>
              <p className="text--sm color--black">{t("form.intro.content")}</p>
            </div>
            <BookForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BookQuery {
    image1: file(relativePath: { eq: "kitchen.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default BookPage
