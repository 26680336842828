import React, { useState, useRef } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import axios from "axios"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import MenuItem from "@material-ui/core/MenuItem"
import { format } from "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined"
import { enGB, el } from "date-fns/locale"
import { ThemeProvider } from "@material-ui/core/styles"
import { theme } from "../components/md-ui-theme"

import Button from "./button"
import { mdiCheckCircle } from "@mdi/js"

export default props => {
  const buttonRef = useRef(null)

  const { t, i18n } = useTranslation("book")

  const [mailSent, setmailSent] = useState(false)

  const [error, setError] = useState(null)

  const emptyForm = {
    name: {
      value: "",
      error: undefined,
      errorMsg: t("form.name.errorMsg"),
      required: true,
    },
    email: {
      value: "",
      error: undefined,
      errorMsg: t("form.email.errorMsg"),
      required: true,
    },
    event_type: {
      value: "",
      error: undefined,
      errorMsg: t("form.event_type.errorMsg"),
      required: true,
    },
    date: {
      value: null,
      error: undefined,
      errorMsg: t("form.date.errorMsg"),
      required: true,
    },
    guests: {
      value: "",
      error: undefined,
      errorMsg: t("form.guests.errorMsg"),
      required: true,
    },
    comments: {
      value: "",
      error: undefined,
    },
    gdpr: {
      value: false,
      errorMsg: t("form.gdpr.errorMsg"),
      error: undefined,
      required: true,
    },
  }

  const [formData, setFormData] = useState(emptyForm)

  const [microtime, setMicrotime] = useState(new Date().getTime() / 1000)

  const regex = {
    email: /[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/,
    date: /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/,
    generic: /.{4,}/,
  }

  const validate = (name, value) => {
    let type
    if (!regex.hasOwnProperty(name)) {
      type = "generic"
    } else {
      type = name
    }

    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        error: value === "" ? undefined : !value.match(regex[type]),
      },
    })
  }

  const formValidate = () => {
    const filtered = Object.keys(formData)
      .filter(k => {
        return (
          formData[k].required &&
          (true === formData[k].error || undefined === formData[k].error)
        )
      })
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: {
            ...formData[key],
            error: true,
          },
        }
      }, {})

    setFormData({
      ...formData,
      ...filtered,
    })

    const hasError = Object.keys(filtered).length !== 0
    setError(hasError)

    return hasError
  }

  const handleFormSubmit = e => {
    e.preventDefault()

    const hasError = formValidate()

    const timepassed = (new Date().getTime() / 1000) - microtime;

    if (hasError || timepassed < 10) {
      buttonRef.current.classList.add("error")
      setTimeout(() => {
        buttonRef.current.classList.remove("error")
      }, 500)

      return false
    }

    setMicrotime(new Date().getTime() / 1000)

    axios
      .post(
        `${process.env.GATSBY_ENDPOINT}/book/`,
        {
          formData,
        },
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(result => {
        if (!result.data.error) {
          setmailSent(true)
          setError(false)
          setFormData(emptyForm)
        } else {
          setError(true)

          let tmpData = { ...formData }

          for (const value of result.data.message) {
            tmpData = {
              ...tmpData,
              [value]: {
                ...tmpData[value],
                error: true,
              },
            }
          }
          setFormData(tmpData)
        }
      })
      .catch(error => setError(error.message))
  }

  const handleChange = e => {
    let field = e.target.name
    let value = e.target.value
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        value: value,
      },
    })
  }

  const handleSelectChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: value.match(regex.generic) ? false : true,
      },
    })
  }

  const handleDateChange = d => {
    setFormData({
      ...formData,
      date: {
        ...formData.date,
        value: d,
        error:
          d === "" ? undefined : !format(d, "dd/MM/yyyy").match(regex.date),
      },
    })
  }

  const handleGDPR = event => {
    setFormData({
      ...formData,
      gdpr: {
        ...formData.gdpr,
        value: event.target.checked,
        error: !event.target.checked,
      },
    })
  }

  return (
    <div className="formWrapper">
      {mailSent || error ? (
        <div
          className={`message ${mailSent ? "success" : "error"}`}
          dangerouslySetInnerHTML={{
            __html: t("form.messages." + (mailSent ? "success" : "error")),
          }}
        ></div>
      ) : (
        ""
      )}
      <form
        action="#"
        method="post"
        autoComplete="off"
        onSubmit={handleFormSubmit}
      >
        <ThemeProvider theme={theme}>
          <div
            className={
              "sp-mb-2 input-error input-error--" + formData.name.error
            }
          >
            <TextField
              onBlur={() => validate("name", formData.name.value)}
              error={formData.name.error}
              helperText={formData.name.error && formData.name.errorMsg}
              type="text"
              label={t("form.name.label")}
              name="name"
              fullWidth={true}
              value={formData.name.value}
              onChange={handleChange}
            />
          </div>
          <div
            className={
              "sp-mb-2 input-error input-error--" + formData.email.error
            }
          >
            <TextField
              onBlur={() => validate("email", formData.email.value)}
              error={formData.email.error}
              helperText={formData.email.error && formData.email.errorMsg}
              type="email"
              label={t("form.email.label")}
              name="email"
              fullWidth={true}
              value={formData.email.value}
              onChange={handleChange}
            />
          </div>
          <div
            className={
              "sp-mb-2 input-error input-error--" + formData.event_type.error
            }
          >
            <TextField
              select
              error={formData.event_type.error}
              helperText={
                formData.event_type.error && formData.event_type.errorMsg
              }
              label={t("form.event_type.label")}
              fullWidth={true}
              name="event_type"
              value={formData.event_type.value}
              onChange={handleSelectChange}
              SelectProps={{
                IconComponent: () => <KeyboardArrowDownOutlinedIcon />,
              }}
            >
              {Object.values(
                t("form.event_type.options", { returnObjects: true })
              ).map((o, i) => (
                <MenuItem key={i} value={o}>
                  {o}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div
            className={
              "sp-mb-2 input-error input-error--" + formData.date.error
            }
          >
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={i18n.language === "gr" ? el : enGB}
            >
              <DatePicker
                orientation="landscape"
                variant="inline"
                autoOk={true}
                InputLabelProps={{
                  shrink: formData.date.value !== null,
                }}
                PopoverProps={{
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "center",
                    horizontal: "center",
                  },
                }}
                format="dd/MM/yyyy"
                label={t("form.date.label")}
                fullWidth={true}
                disablePast={true}
                error={formData.date.error}
                helperText={formData.date.error && formData.date.errorMsg}
                name="date"
                invalidDateMessage={t("form.dates.error")}
                value={formData.date.value}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div
            className={
              "sp-mb-2 input-error input-error--" + formData.guests.error
            }
          >
            <TextField
              select
              error={formData.guests.error}
              helperText={formData.guests.error && formData.guests.errorMsg}
              fullWidth={true}
              label={t("form.guests.label")}
              name="guests"
              value={formData.guests.value}
              onChange={handleSelectChange}
              SelectProps={{
                IconComponent: () => <KeyboardArrowDownOutlinedIcon />,
              }}
            >
              <MenuItem value="1-16">1 - 16</MenuItem>
              <MenuItem value="16-50">16 - 50</MenuItem>
              <MenuItem value="50-70">50 - 70</MenuItem>
            </TextField>
          </div>
          <div className="sp-mb-2">
            <TextField
              type="text"
              label={t("form.comments.label")}
              multiline
              name="comments"
              fullWidth={true}
              value={formData.comments.value}
              onChange={handleChange}
            />
          </div>
          <div className="sp-mb-4">
            <FormControl error={formData.gdpr.error}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      required={true}
                      checked={formData.gdpr.value}
                      onChange={handleGDPR}
                      name="gdpr"
                      color="primary"
                    />
                  }
                  label={t("form.gdpr.label")}
                ></FormControlLabel>
              </FormGroup>
            </FormControl>
          </div>
          <div className="text-right">
            <Button
              ref={buttonRef}
              className="button button--light button--icon flex-shrink-0 ml-auto"
              type="submit"
              icon={mdiCheckCircle}
            >
              {t("form.submit")}
            </Button>
          </div>
        </ThemeProvider>
      </form>
    </div>
  )
}
